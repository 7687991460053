<template>
    
  <div
    class="menu"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <el-tabs v-model="activeName" @tab-click="firsthandleClick">
      <el-tab-pane label="自定义物流" name="first"> </el-tab-pane>
      <el-tab-pane label="API对接物流" name="second"> </el-tab-pane>
      <el-tab-pane label="第三方仓物流" name="third"></el-tab-pane>
      <el-tab-pane label="平台物流" name="fourth"> </el-tab-pane>
      <el-tab-pane label="头程物流" name="fifth"> </el-tab-pane>
    </el-tabs>
    <div class="content">
      <div class="firstbox">
        <div
          style="
            display: flex;
            height: 35px;
            align-items: center;
            padding: 0 20px;
          "
        >
          <div style="font-size: 14px; color: #000; font-weight: 700">
            物流商
          </div>
          <div style="margin-left: 60px" v-show="activeName == 'first'">
            <el-button type="text" @click="add">添加</el-button>
          </div>
          <div style="margin-left: 60px" v-show="activeName == 'third'">
            <el-button type="text" @click="setting">设置</el-button>
          </div>
        </div>
        <div
          class="pp"
          :style="{ color:firstid == item.id?'#409EFF':'',}"
          v-for="(item, index) in arr"
          @click="firstbtn(item.id)"
          :key="index"
          v-show="item.status == 1"
        >
          <div
            
            style="font-size: 13px; height: 30px; line-height: 30px"
          >
            <span>{{ item.providerName }}</span>
          </div>
          <div style="padding-right: 55px">
            <el-dropdown
              trigger="click"
              @command="handleCommand"
              style="float: right; margin-top: 8px"
              v-if="item.status == 1"
            >
              <span class="iconfont icon-gengduo"> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="detail">详 情</el-dropdown-item>
                <el-dropdown-item command="edit">编 辑</el-dropdown-item>
                <el-dropdown-item command="stop">停 用</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="tingyong">
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            active-text-color="#006eff"
          >
            <el-submenu index="1">
              <template slot="title">
                <span style="font-weight: 600; font-size: 14px">已停用</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="item.id"
                  v-for="(item, index) in arr"
                  :key="index"
                  @click="firstbtn(item.id)"
                  v-show="item.status == 0"
                  >{{ item.providerName }} 
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="iconfont icon-gengduo"> </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="open(item.id)">启 用1</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="secondbox">
        <div
          style="
            display: flex;
            height: 35px;
            align-items: center;
            padding: 0 20px;
          "
        >
          <div style="font-size: 14px; color: #000; font-weight: 700">
            物流渠道
          </div>
          <div style="margin-left: 60px">
            <el-button type="text" @click="addway">添加</el-button>
          </div>
        </div>

        <div style="width: 180px; float: left; margin: 5px 0 10px 8px">
          <el-input
            placeholder="搜索"
            suffix-icon="el-icon-search"
            v-model="channelName"
            @keyup.enter.native = "find"
          >
          </el-input>
        </div>
        <div
          class="pp"
          v-for="(item, index) in arrs"
          :style="{ color:secondId == item.id?'#409EFF':'',}"
           @click="waysBtn(item.id)"
          :key="index"
          v-show="item.status == 1"
        >
        <!-- style="width: 160px; height: 32px; margin-left: 10px" -->
          <!-- <el-button type="text" @click="waysBtn(item.id)">{{
            item.channelName
          }}</el-button> -->
          <div
            style="font-size: 13px; height: 30px; line-height: 30px"
          >
            <span>{{ item.channelName }}</span>
          </div>
          <div style="margin-right: 12px;">
          <el-dropdown
            trigger="click"
            @command="handleCommand"
            style="float: right; margin-top: 8px"
          >
            <span class="iconfont icon-gengduo"> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="twoStop(item.id)">关 闭</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          </div>
        </div>
        <div class="tingyong">
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            active-text-color="#006eff"
          >
            <el-submenu index="1">
              <template slot="title">
                <span style="font-weight: 600; font-size: 14px">已停用</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="item.id"
                  v-for="(item, index) in arrs"
                  :key="index"
                  @click="waysBtn(item.id)"
                  v-show="item.status == 0"
                  >{{ item.channelName }} 
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="iconfont icon-gengduo"> </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="secondopen(item.id)">启 用</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="thirdbox" v-if="ways">
        <div class="basic-infomation">
          <div class="title">基本信息</div>
          <el-form
            :inline="true"
            :model="thirdList"
            class="demo-form-inline"
            label-position="right"
            label-width="80px"
            ref="thirdList"
          >
            <el-form-item
              label="物流方式"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="channelName"
            >
              <el-input
                placeholder=""
                v-model="thirdList.channelName"
                style="width: 300px"
              >
              </el-input> </el-form-item
            ><br />
            <el-form-item label="物流代码">
              <el-input
                placeholder=""
                v-model="thirdList.channelCode"
                style="width: 300px"
              >
              </el-input> </el-form-item
            ><br />
            <el-form-item label="备注">
              <el-input
                type="textarea"
                placeholder=""
                v-model="thirdList.tips"
                style="width: 300px"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer">
          <el-button style="width: 100px">取消</el-button>
          <el-button type="primary" style="width: 100px" @click="addWay" v-if="mybtn0">保存</el-button>
          <el-button type="primary" style="width: 100px" @click="addWayedit" v-if="mybtn1">保存</el-button>
        </div>
      </div>
      <div class="thirdbox" v-if="waydetail">
        <div class="top">
          <div class="top_title">
            {{ detailList.channelName }}
          </div>
          <div class="top_btn">
            <el-button style="width: 60px" @click="updateway">关 闭</el-button>
            <el-button type="primary" style="width: 60px" @click="wayedit"
              >编 辑</el-button
            >
          </div>
        </div>
        <div class="tips">备注：{{ detailList.tips }}</div>
      </div>
    </div>

    <!-- 添加物流商 -->
    <el-dialog
      width="450px"
      :title="title"
      :visible.sync="addBox"
      :close-on-click-modal="false"
    >
      <hr />
      <el-form
        :inline="true"
        :model="addObj"
        class="demo-form-inline"
        label-position="right"
        label-width="70px"
        ref="addObj"
      >
        <el-form-item
          label="物流商"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
          prop="providerName"
        >
          <el-input
            placeholder=""
            v-model="addObj.providerName"
            style="width: 300px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="代码">
          <el-input
            placeholder=""
            v-model="addObj.providerCode"
            style="width: 300px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            placeholder=""
            v-model="addObj.tips"
            style="width: 300px"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div style="margin-left: 242px">
        <el-button @click="addCancle">关 闭</el-button>
        <el-button
          type="primary"
          @click="addConfirm"
          style="background-color: #006eff"
          v-if="title == '添加物流商'"
          >保 存</el-button
        >
        <el-button
          type="primary"
          @click="updateConfirm"
          style="background-color: #006eff"
          v-if="title == '编辑物流商'"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog
      width="400px"
      title="物流商详情"
      :visible.sync="firstbox"
      :close-on-click-modal="false"
    >
      <hr />
      <el-form
        :inline="true"
        :model="firstboxList"
        class="demo-form-inline"
        label-position="right"
        label-width="70px"
        ref="firstboxList"
      >
        <el-form-item label="物流商:" style="color: #000">
          {{ firstboxList.providerName }} </el-form-item
        ><br />
        <el-form-item label="代码:" style="color: #000">
          {{ firstboxList.providerCode }} </el-form-item
        ><br />
        <el-form-item label="备注:" style="color: #000">
          {{ firstboxList.tips }}
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="list"></div>
  </div>
</template>

<script>
import {
  Add,
  getlistPage,
  Addway,
  getwayList,
  wayUpdate,
  detailbtn,
  getdetail,
  update,
  updateStatus,
  qudaoUpdate,
} from "@/api/logistics";
export default {
  data() {
    return {
      activeName: "first",
      isActive: false,
      isError: false,
      title: "",
      detailList: {},
      input: "",
      addBox: false,
      arr: [],
      arrs: [],
      firstboxList: {},
      addObj: {
        tips: "",
        providerName: "",
        providerCode: "",
        providerType: 1,
      },
      channelName:'',
      thirdList: {
        channelName: "",
        channelCode: "",
        tips: "",
        providerId: "0",
      },
      show: false,
      ways: false,
      mybtn0:false,
      mybtn1:false,
      waydetail: false,
      firstbox: false,
      firstid: "",
      num:1,
    };
  },
  created() {
    this.getData()
  },
  mounted() {},
  methods: {
    setting() {
      this.$router.push("/warehouseSetting");
    },
    find(){
      let data = {
        providerId: this.firstid,
        channelName: this.channelName
      }
      getwayList(data).then((res) => {
        this.arrs = res.data.data;
      });
    },
    //物流商页面初始化
    async getData() {
      try {
        let data = {
          providerType: this.num,
        };
        const res = await getlistPage(data);
        if ((Object.is(res.code), 200)) {
          this.arr = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {}
    },
    add() {
      this.addBox = true;
      this.title = "添加物流商";
    },
    addCancle() {
      this.addBox = false;
    },
    addConfirm() {
      this.$refs.addObj.validate((valid) => {
        if (valid) {
          Add(this.addObj).then((res) => {
            if (res.data.code == 200) {
              this.addBox = false;
              this.getData();
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    firstbtn(id) {
      this.isActive = true;
      this.waydetail = false;
      this.firstid = id;
      let data = {
        providerId: this.firstid,
        channelName: this.channelName
      }
      getwayList(data).then((res) => {
        this.arrs = res.data.data;
      });
    },
    addway() {
      this.ways = true;
      this.waydetail = false;
      this.thirdList =  {
        channelName: "",
        channelCode: "",
        tips: "",
        providerId: this.firstid,
      },
      this.mybtn0 = true;
      this.mybtn1 = false;

    },
    //编辑
    wayedit(){
      this.ways = true;
      this.waydetail = false;
      this.mybtn0 = false;
      this.mybtn1 = true;
      this.thirdList = this.detailList;
    },
    //渠道添加保存
    addWay() {
      this.$refs.thirdList.validate((valid) => {
        if (valid) {
          Addway(this.thirdList).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.firstbtn(this.firstid);
              this.ways = false;

            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑保存
    addWayedit() {
      this.$refs.thirdList.validate((valid) => {
        if (valid) {
          qudaoUpdate(this.thirdList).then((res) => {
            if (res.data.code == 200) {
              this.getData();
              this.firstbtn(this.firstid);
              this.ways = false;
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    updateway() {
      this.waydetail = false;

    },
    waysBtn(id) {
      this.waydetail = true;
      this.ways = false;
      this.secondId = id;
      detailbtn(id).then((res) => {
        this.detailList = res.data.data;
      });
    },
    //操作
    handleCommand(command) {
      if (command == "detail") {
        getdetail(this.firstid).then((res) => {
          this.firstbox = true;
          if (res.data.code == 200) {
            this.firstboxList = res.data.data;
          } else {
            this.$message.warning(res.data.message);
          }
        });
      }
      if (command == "edit") {
        getdetail(this.firstid).then((res) => {
          if (res.data.code == 200) {
            this.addBox = true;
            this.title = "编辑物流商";
            this.addObj = res.data.data;
          } else {
            this.$message.warning(res.data.message);
          }
        });
      }
      if (command == "stop") {
        let data = {
          id: this.firstid,
          status: 0,
        };
        updateStatus(data).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getData();
          } else if (res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    //open
    open(id){
      let data = {
          id: id,
          status: 1,
        };
      updateStatus(data).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getData();
          } else if (res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        });
    },
    //物理渠道关闭
    twoStop(id){
      let data = {
        id: id,
        status: 0,
      };
      wayUpdate(data).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.firstbtn(this.firstid);

          this.getData();
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },

    //编辑确认
    updateConfirm() {
      let data = {
        id: this.firstid,
        channelCode: this.addObj.providerCode,
        providerName: this.addObj.providerName,
        tips: this.addObj.tips,
      };
      update(data).then((res) => {
        if (res.data.code == 200) {
          this.addBox = false;
          this.getData();
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    firsthandleClick(){
      console.log(this.activeName);
      if( this.activeName == "first"){
        this.num = 1
        this.getData();
      }else{
         this.num = 2;
          this.getData();
      }
    },
    secondopen(id){
      let data = {
        id: id,
        status: 1,
      };
      wayUpdate(data).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
          this.firstbtn(this.firstid);
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.firstbox {
  flex-direction: column;
  height: 100%;
  display: flex;
  background-color: #f2f4f7;
  width: 200px;
  border-right: 1px solid #e1e3e8;
}
.secondbox {
  height: 100%;
  width: 200px;
  flex-direction: column;
  display: flex;
  border-right: 1px solid #e1e3e8;
}
.thirdbox {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 22px 20px;
  width: calc(100% - 403px);
  .basic-infomation {
    height: 200px;
  }
}
.menu {
  height: calc(100% - 100px);
}
.title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  border-left: 2px solid #006eff;
  padding-left: 10px;
  line-height: 14px;
}
.content {
  height: 100%;
  display: flex;
}
.footer {
  height: 80px;
  position: fixed;
  display: flex;
  bottom: 0;
  width: calc(100% - 403px);
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 4%);
  align-items: center;
  justify-content: center;
}
.top {
  height: 34px;
  display: flex;
  justify-content: space-between;
}
.top_title {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #000;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: 6px;
}
.tips {
  display: flex;

  min-width: 48px;
  color: #888;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin-bottom: 32px;
}
.tingyong {
  border-top: 1px solid #e1e3e8;
}
.pp {
  cursor: pointer;
  padding-left: 27px;
  height: 32px;
  display: flex;
  justify-content: space-between;
}
.transition-box {
  border-bottom: 1px solid #e7e9ee;
  height: 35px;
  line-height: 35px;
  padding-left: 19px;
  font-size: 14px;
  color: #888;
}
::v-deep .tingyong .el-submenu__title {
  height: 36px;
  background-color: #f2f4f7;
  line-height: 36px;
  font-size: 12px;
  color: #555;
}
::v-deep .tingyong .el-menu-item {
  height: 36px;
  line-height: 36px;
  background-color: #f2f4f7;
  font-size: 12px;
  padding-left: 20px !important;
}
::v-deep .tingyong .el-menu-item-group__title{
  padding: 0;
}
</style>