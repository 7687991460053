import request from '@/utils/request.js'
//新增
export function Add(data) {
    return request({
        url:'/api/logistics/provider/add',
        method:'post',
        data
    });
}
//物流渠道  新增
export function Addway(data) {
    return request({
        url:'/api/logistics/channel/add',
        method:'post',
        data
    });
}
//渠道 查询
export function getwayList(params) {
    return request({
        url:'/api/logistics/channel/list',
        method:'get',
        params
      
    });
}
//渠道  详情
export function detailbtn(id) {
    return request({
        url:'/api/logistics/channel/detail/' + id,
        method:'get',
      
    });
}
//详情
export function getdetail(id) {
    return request({
        url:'/api/logistics/provider/detail/' + id,
        method:'get',
      
    });
}
//查询
export function getlistPage(params) {
    return request({
        url:'/api/logistics/provider/list',
        method:'get',
        params
      
    });
}
//修改确认
export function update(data) {
    return request({
      url: "/api/logistics/provider/update",
      data,
      method: "put"
    })
  }
//物流渠道 修改状态
export function wayUpdate(data) {
    return request({
      url: "/api/logistics/channel/updateChannelStatus",
      data,
      method: "put"
    })
  }
//物流商 修改状态
export function updateStatus(data) {
    return request({
      url: "/api/logistics/provider/updateStatus",
      data,
      method: "put"
    })
  }
  //物流渠道 编辑
  export function qudaoUpdate(data) {
    return request({
      url: "/api/logistics/channel/update",
      data,
      method: "put"
    })
  }
